<template>
  <label :for="id" class="label"
    ><slot></slot><sup class="text-red-600" v-if="required">*</sup></label
  >

  <input
    :name="id"
    :type="type"
    :id="id"
    :list="list"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    class="input disabled:bg-gray-100"
    :class="{ 'ring ring-red-500 ring-opacity-50': error }"
    :step="step"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :autofocus="autofocus"
    :placeholder="placeholder"
  />

  <p v-if="limit || error" class="flex flex-wrap justify-between gap-4">
    <span>
      <span class="text-xs text-red-600" role="alert" v-if="error">
        {{ error }}
      </span>
    </span>
    <span>
      <span
        class="text-xs text-right"
        :class="{ 'text-green-600': withinLimit, 'text-red-600': !withinLimit }"
        role="alert"
        v-if="limit"
      >
        {{ characterCount }} characters of {{ limit }}
      </span>
    </span>
  </p>
</template>

<script>
export default {
  props: {
    id: String,
    error: String,
    modelValue: [String, Number],
    type: {
      type: String,
      default: 'text'
    },
    step: String,
    autocomplete: String,
    limit: Number,
    list: String,
    colour: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: String,
  },
  emits: ["update:modelValue", "change"],
  computed: {
    characterCount() {
      return this.modelValue.length;
    },
    withinLimit() {
      return this.limit && this.characterCount <= this.limit;
    },
  },
};
</script>
